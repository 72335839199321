import * as React from "react"
import { Link } from "gatsby"
import { createTheme, ThemeProvider } from "@mui/material";
import AnimeDisplay from "../../components/AnimeDisplay";

const theme = createTheme()



function Search({ params }) {
    return (
        <ThemeProvider theme={theme} >
    <AnimeDisplay search={params.search} />
      </ThemeProvider>
      )
}

export default Search